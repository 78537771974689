import { type LinksFunction } from '@remix-run/node'
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLocation,
	useParams,
} from '@remix-run/react'
import { useEffect } from 'react'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'

export const links: LinksFunction = () => [
	{ rel: 'stylesheet', href: tailwindStyleSheetUrl },
]

export default function App() {
	const location = useLocation()
	const { apiKey } = useParams()

	useEffect(() => {
		if (parent) {
			//rempve the apiKey as we don't want this to be sent to the parent
			let newPath = location.pathname.replace(`${apiKey}/`, '')

			// need to figure out how to filter some of these
			//   if (location.search) {
			//     newPath += location.search
			//   }
			const eventData: Record<string, string> = {
				event: 'webui-url-change',
				newPath,
			}
			parent.postMessage(eventData, '*')
		}
	}, [location, apiKey])

	return (
		<html lang="en" className="bg-gray-white h-full">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<meta name="robots" content="noindex,follow" />
				<script src="https://cdnjs.cloudflare.com/ajax/libs/flowbite/2.3.0/datepicker.min.js"></script>
				<Meta />
				<Links />
			</head>
			<body className="h-full antialiased">
				<Outlet />
				<ScrollRestoration />
				<Scripts />
			</body>
		</html>
	)
}
